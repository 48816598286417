<template>
  <el-card v-loading="tableLoading" class="box-card">
    <div class="title">
      <Header />
    </div>
    <div class="setting-detail">
      <topHeader />
    </div>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="mt30">
      <el-breadcrumb-item :to="{ path: '/kyc' }">KYC</el-breadcrumb-item>
      <el-breadcrumb-item>{{$route.query.id?translateTitle('编辑KYC身份'):translateTitle('添加KYC身份')}}</el-breadcrumb-item>
    </el-breadcrumb>
      <div class="change-form mt30">
        <el-form
          label-position="top"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
         <div class="title mb30">{{translateTitle('进行认证')}}</div>
          <el-form-item v-if="active == '1'"  :label="translateTitle('居住信息')" prop="nationality">
            <el-select
              class="svg-container"
              v-model="ruleForm.nationality"
              filterable
              clearable
              @change="changeNationality"
              :placeholder="translateTitle('输入国籍')"
            >
              <template #prefix>
            <span class="flex">
              <img
                v-if="nationalitylogo"
                :src="nationalitylogo"
                width="24"
                height="24"
              />
            </span>
          </template>
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
               <div class="disal">
                <img
                  :src="item.logo"
                  width="24"
                  height="24"
                />
                <span class="select-item">{{ item.name }}</span>
            </div>
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="active == '1'" class="text-more">
            <div class="title1">{{translateTitle('你可以使用以下功能')}}</div>    
            <div class="line"> 
                <img width="15" src="../../assets/imgs/ico_dui_lv@2x.png">
                {{translateTitle('理财')}}
            </div>
            <div class="line"> 
                <img  width="15" src="../../assets/imgs/ico_dui_lv@2x.png">
                {{translateTitle('转账')}}
            </div>
            <div class="line"> 
                <img width="15" src="../../assets/imgs/ico_dui_lv@2x.png">
                {{translateTitle('充值账号')}}
            </div>
            <div class="line"> 
                <img width="15" src="../../assets/imgs/ico_dui_lv@2x.png">
                {{translateTitle('开通加密货币')}}
            </div>
            <div class="line"> 
                <img width="15" src="../../assets/imgs/ico_dui_lv@2x.png">
                {{translateTitle('绑定加密货币万事达卡')}}
            </div>
            <div class="line"> 
                <img width="15" src="../../assets/imgs/ico_dui_lv@2x.png">
                {{translateTitle('万事达卡额度提升每月50000')}}
            </div>
          </div>
          <!-- <el-form-item v-if="active == '2'" :label="translateTitle('证件类型')" prop="idType">
            <el-select v-model="ruleForm.idType" :placeholder="translateTitle('请选择')">
              <el-option
                v-for="item in options1"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item v-if="active == '2'" :label="translateTitle('护照号码')" prop="idNumber">
            <el-input
              v-model="ruleForm.idNumber"
              clearable
              :placeholder="translateTitle('输入证件号码')"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="active == '2'" :label="translateTitle('护照到期')" prop="idExpire">
            <el-date-picker
              v-model="ruleForm.idExpire"
              clearable
              format="yyyy-MM-dd"
              :placeholder="translateTitle('选择日期')"
              type="date"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item v-if="active == '2'" :label="translateTitle('文件上传')" prop="filePath">
            <el-upload
              list-type="picture-card"
              :action= "fileUrl+ `/sys/file/upload`"
              :before-upload="handleBeforeUpload"
              class="avatar-uploader"
              :headers="uploadHeader"
              :on-error="handleError"
              :on-success="handleUploadSuccess"
              :show-file-list="false"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <el-image
                style="width: 100%; height: 100%"
                v-if="ruleForm.filePath"
                :src="fileUrl + ruleForm.filePath"
                fit="fill"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">{{ translateTitle('只能上传文件') }}</div>
            </el-upload>
          </el-form-item>
          <div v-if="active == '1'">
              <el-button :loading="btnloading" class="sure-btn"  @click="active = 2" > {{translateTitle('继续')}} </el-button>
          </div>
          <div v-else>
            <el-button class="close-btn" @click="active = 1">{{
              translateTitle("上一步")
            }}</el-button>
            <el-button  :loading="btnloading" class="sure-btn"  @click="onSure('ruleForm')" >{{translateTitle('确认')}} </el-button>
          </div>
        </el-form>
      </div>
    <el-dialog :visible.sync="dialogVisible">
      <img
        width="100%"
        :src="fileUrl + checkRow.filePath"
        alt=""
      />
    </el-dialog>
  </el-card>
</template>

<script>
import { getToken } from "@/utils/token";
import Headers from "../../components/accountHeader/index.vue";
import topHeader from "../../components/topHeader/index"
import { addKyc, KYCPage, getKycInfo } from "@/api/kyc";
import { getNational } from "@/api/accountManage";
import axios from "axios";
import { translateTitle } from "@/utils/i18n";
export default {
  components: { Headers, topHeader },
  filters: {
      statusFilter(status) {
        const statusMap = {
          0: '未上传',
          1: '审核中',
          2: '银行已拒绝',
          3: '银行审核通过',
        }
        return statusMap[status]
      }
    },
  data() {
    return {
      value1: false,
      total: 20,
      size: 10,
      tableData: [],
      nationalitylogo: '',
      active: '1',
      tableLoading: false,
      options1: ["passport", "IdentityCard"],
      uploadHeader: {
        token: getToken(),
      },
      rules: {
        filePath: [
          { required: true, message: this.translateTitle("请上传图片"), trigger: "change" },
        ],
        nationality: [
          { required: true, message: this.translateTitle("请输入国籍"), trigger: "change" },
        ],
        gender: [{ required: true, message: this.translateTitle("请选择性别"), trigger: "change" }],
        idNumber: [
          { required: true, message: this.translateTitle("请输入证件号码"), trigger: "change" },
        ],
        idType: [
          { required: true, message: this.translateTitle("请选择证件类型"), trigger: "change" },
        ],
        idExpire: [
          { required: true, message: this.translateTitle("请选择过期时间"), trigger: "change" },
        ],
      },
      bindingTitle: this.translateTitle("新增KYC"),
      ruleForm: {
        fileName: "",
        filePath: "",
        id: "",
        idExpire: "",
        idNumber: "",
        idType: "",
        nationality: "",
        fileType: "1",
      },
      activeName: "first",
      pageSize: 10,
      page: 1,
      Visible: false,
      options: [],
      dialogVisible: false,
      btnloading: false,
      globalLoading: null,
      checkRow: {},
      kycType: "",
      fileUrl: process.env.NODE_ENV == 'production' ? 'https://api.bankeracard.com/client_side' : 'http://13.214.25.79:9001/client_side',
    };
  },
  created() {
    this.getNationalData();
    this.ruleForm.id = this.$route.query.id
    if(this.ruleForm.id) {
      this.getData(this.ruleForm.id)
    }
  },
  methods: {
    translateTitle,
    getData(val) {
      getKycInfo(val).then(res => {
        if(res.code == 200) {
          this.ruleForm = res.data
        }
      })
    },
    handleBeforeUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        const isJPG =
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error(this.translateTitle('格式错误'));
        }
        if (!isLt2M) {
          this.$message.error(this.translateTitle("请选择小于2M的文件"));
        }
        this.loading = true;
        return isJPG && isLt2M;
      },
    handleError() {
      this.loading = false;
      this.$message.error(this.translateTitle("上传失败"));
    },
    handleUploadSuccess(res) {
      this.loading = false;
      if (res.code !== 200) {
        this.$message.warning(res.msg);
        return;
      }
      this.$message.success(this.translateTitle("上传成功"));
      this.ruleForm.filePath = res.data.filePath;
      this.ruleForm.fileName = res.data.fileName;
      // Object.assign(this.uploadRow, res.data)
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    showdialog(row) {
      this.dialogVisible = true;
      this.checkRow = row;
    },
    onClose() {
      this.$refs["ruleForm"].resetFields();
      this.Visible = false;
    },
    onSure(ruleForm) {
      let params;
      let _request;
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          // this.btnloading = true
          this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
          addKyc(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.translateTitle("操作成功"));
              // this.getTableData();
              this.Visible = false;
              this.$router.go(-1)
              this.$refs["ruleForm"].resetFields();
            } else {
               this.$message.error(this.translateTitle(res.msg));
            }
          }).finally(() => {
            // this.btnloading = false
            this.globalLoading.close()
          });
          // alert("submit!");
        } else {
          return false;
        }
      });
      this.visible = true;
    },
    async getNationalData() {
      let res = await getNational();
      this.options = res.data;
    },

    changeNationality(data) {
        let obj  = this.options.find(item => item.name === data)
        this.nationalitylogo = obj.logo
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 26px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-bottom: 20px;
  text-align: right;
}
.setting-detail {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.succ {
  color: #3ad3b9;
}
.err {
  color: #d33a3a;
}
.change-form {
  .title {
        text-align: left;
        font-size: 18px;
        font-weight: 700;
        color: #31363E;
        margin-bottom: 30px !important;
    }
    .text-more {
      margin-bottom: 15px;
      .title1 {
          font-size: 16px;
          font-weight: 400;
          color: #31363E;
          margin-bottom: 15px;
      }
      .line {
          line-height: 36px;
          font-size: 16px;
          font-weight: 400;
          color: #31363E;
          img {
              vertical-align: middle;
          }
      }
      
    }
    ::v-deep {
         .el-input {
          width: 50%;
        }
      }
    .sure-btn {
  background-color: #165BA4;
  border-radius: 12px;
  width: 208px;
height: 48px;
  color: #fff;
  font-weight: 400;
}
.close-btn {
  border-radius: 12px;
  width: 208px;
height: 48px;
}
}

.select-item {
  margin-left: 10px;
}
</style>
