//系统设置相关
import request from "@/utils/request";

///获取个人信息
export const getUserinfo = () =>
request({
  url: "/stylopay/userinfo/get",
  method: "GET",
});
//添加联系人
export const addContact = (data) =>
request({
  url: "/contact/addContact",
  method: "post",
  data,
});
//联系人分页查询
export const contactPage = (data) =>
request({
  url: "/contact/page",
  method: "post",
  data,
});

///删除联系人

export const deleteDontact = (id) =>
  request({
    url: `/contact/delete/${id}`,
    method: "post",
  });
  
 // 获取国家alpha2代码
  export const getNational = () =>
  request({
    url: "/stylopay/userinfo/getNational",
    method: "GET",
  });

  //  通过银行转账
  export const bank_transfer = (data) =>
  request({
    url: `/transfer/bank_transfer`,
    method: "post",
    data,
  });
   //  加密货币转账
   export const coin_transfer = (data) =>
   request({
     url: `/transfer/coin_transfer`,
     method: "post",
     data,
   });

   //通过银行卡号转账/
   export const bank_card_transfer = (data) =>
   request({
     url: `/transfer/bank_card_transfer`,
     method: "post",
     data,
   });
  //创建银行账户
export const createMccard = (data) =>
  request({
    url: `/stylopay/userinfo/createMccard`,
    method: "post",
    data,
  });
  
  //查询用户银行账户明细
  export const bankAccountStatement = (data) =>
  request({
    url: `/account/bankAccountStatement`,
    method: "post",
    data,
  });
  
//查询用户加密货币明交易明细
export const cryptoStatement = (data) =>
  request({
    url: `/account/cryptoStatement`,
    method: "post",
    data,
  });

//添加或更新个人信息
export const addUserInfo = (data) =>
  request({
    url: `/stylopay/userinfo/addUserInfo`,
    method: "post",
    data,
  });

//证件类型/
export const getIdTypes = () =>
  request({
    url: `/stylopay/userinfo/getIdTypes`,
    method: "GET",
  });
 