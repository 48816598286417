// KYC
import request from "@/utils/request";
///新增或编辑KYC
export const addKyc = (data) =>
	request({
		url: "/kyc/addKyc",
		method: "post",
		data,
	});
//KYC查询列表
export const KYCPage = (data) =>
	request({
		url: "/kyc/page",
		method: "post",
		data,
	});
//kyc详情
export const getKycInfo = (id) =>
	request({
		url: `/kyc/getKycInfo/${id}`,
		method: "GET",
	});
// 获取kyc状态
export const kycStatus = () =>
	request({
		url: "/kyc/kycStatus",
		method: "GET",
	});
// KYC认证连接和状态
export const userKycDetails = () =>
	request({
		url: "/kyc/userKycDetails",
		method: "GET",
	});
// 代理查询
export const proxyList = (data) =>
	request({
		url: "/baseuser/proxyList",
		method: "post",
		data,
	});
// 新增代理
export const addProxy = (data) =>
	request({
		url: "/baseuser/addProxy",
		method: "post",
		data,
	});
// 申请记录
export const applyPage = (data) =>
	request({
		url: "/node/applyPage",
		method: "post",
		data,
	});
// 申请节点
export const applyNode = (data) =>
	request({
		url: "/node/applyNode",
		method: "post",
		data,
	});
// 查询用户是否申请通过
export const checkOnceApply = () =>
	request({
		url: "/node/checkOnceApply",
		method: "GET",
	});
// 取消申请节点
export const cancelApply = (data) =>
	request({
		url: "/node/cancelApply",
		method: "post",
		data,
	});
// 合伙人（节点）列表
export const nodelist = () =>
	request({
		url: "/node/list",
		method: "GET",
	});