<template>
	<div class="top-header">
		<div class="header-title">{{ userInfo.userName || 'Hello Mark' }}
		<p v-if="iframeLink.kycStatus=='not_submitted'" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">{{translateTitle('未提交')}}
		</p>
		<p v-if="iframeLink.kycStatus=='pending'" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">{{translateTitle('待办')}}
		</p>
		<p v-if="iframeLink.kycStatus=='submitted'" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">{{translateTitle('已提交')}}
		</p>
		<p v-if="iframeLink.kycStatus=='under_review'" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">{{translateTitle('审核中')}}
		</p>
		<p v-if="iframeLink.kycStatus=='rejected'" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'">{{translateTitle('已拒绝')}}
		</p>
		<p v-if="iframeLink.kycStatus=='approved'" class="tip" :class="kycStatusInfo.kycStatus? '':'gray'"><img width="15px"
				src="../../assets/imgs/newImg/icon_gouxuan.png">{{translateTitle('已通过')}}
		</p>
		</div>
		<div v-if="userInfo.registerToBank" class="header-text">{{translateTitle('银行户口号码')}}<span
				class="ml10">{{ userInfo.frontCardNumber }}</span></div>
	</div>
</template>

<script>
	import {
		kycStatus,
		userKycDetails
	} from "@/api/kyc";
	import {
		mapState
	} from "vuex";
	import {
		translateTitle
	} from "@/utils/i18n";
	export default {
		props: {
			// 显示/隐藏
			title: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				type: 1,
				iframeLink: {},
				kycStatusInfo: {},
			};
		},
		computed: {
			...mapState({
				userInfo: (state) => state.user.user,
			}),
		},
		created() {
			// this.getTableData();
			// this.getKycStatus()
			this.userKycDetails()
		},
		methods: {
			translateTitle,
			changeType() {
				this.type = 2;
			},
			async userKycDetails() {
				let res = await userKycDetails();
				if (res.code == 200) {
					this.iframeLink = res.data
				} else {
					this.$message.error(res.msg);
				}
			},
			//获取kyc状态
			async getKycStatus() {
				let res = await kycStatus();
				if (res.code == 200) {
					this.kycStatusInfo = res.data
				} else {
					this.$message.error(res.msg);
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.top-header {

		// display: flex;
		// justify-content: space-between;
		// align-items: center;
		.header-title {
			font-size: 36px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			font-weight: bold;
			color: #31363E;
			line-height: 26px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;

			.tip {
				margin-left: 24px;
				background: rgba(55, 34, 0, 0.1);
				border-radius: 4px;
				color: rgba(144, 117, 54, 1);
				padding: 4px 10px;
				border: 1px solid rgba(55, 34, 0, 0.8);
				font-size: 15px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				line-height: 26px;

				img {
					margin-right: 5px;
					vertical-align: middle;
				}
			}

			.gray {
				background: rgba(55, 34, 0, 0.1);
				border: 1px solid rgba(55, 34, 0, 0.8);
			}
		}

		.header-text {
			font-size: 18px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #31363E;
			line-height: 16px;
			margin-bottom: 25px;
		}

	}

	.right {
		display: flex;
		align-items: center;

		.text {
			text-align: right;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;

			.name1 {
				color: #31363e;
				margin-bottom: 5px;
			}

			.name2 {
				color: #b9bfca;
			}
		}

		.el-dropdown {
			display: flex;
			align-items: center;

			img {}
		}

		.img1 {
			width: 50px;
			margin: 0 5px 0 5px;
		}

		.img2 {
			width: 20px;
			height: 20px;
		}
	}
</style>